import Service from "../Service";
const resource = "general/"

export default {
    searchsunarp(document, requestID) {
        return Service.post(resource + "searchsunarp", {}, {
            params: { document, requestID: requestID },
        });
    },

};
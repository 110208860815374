<template>
<div>

    <!-- <v-dialog v-if="processing" v-model="processing" :persistent="messageProcessing.length==0" width="400">
        <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
                Procesando... Por favor espere...
                <v-progress-linear indeterminate color="white" class="mb-0 mt-2"></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <div class="text--white pt-4">
                    Error.. <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog> -->
    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

    <v-dialog v-model="dialogHtmlEmail" v-if="dialogHtmlEmail" fullscreen hide-overlay style="display: contents; max-height: 100%;">
        <s-toolbar close @close="dialogHtmlEmail=false;" :label="'Plantilla Correo enviado'"></s-toolbar>
        <v-row style="margin:auto; display: grid" v-html="miHtmlEmail">
        </v-row>

    </v-dialog>

    <div>
        <s-toolbar close :label="'Cotización: ' + cotizationLocal.CttDocumentNumber" @close="$emit('onClose')" />
        <v-card>
            <v-col>
                <v-row>
                    <v-col :lg="$fun.isAdmin() ? 4: 12" cols="12">
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        Datos Generales.
                                    </v-card-title>

                                </v-card>
                                <v-card outlined width="100%" style="margin-top:10px">
                                <v-row>
                                        <v-col cols="12" class="text-center">
                                            Tiempo estimado
                                            <h1 style="transform: scale(0.8);">{{cotizationLocal.CttDayExpiration}} días.</h1>
                                            <!-- (No incluye sabados y domingos) -->
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mt-3"></v-divider>
                                    <v-row>
                                        <v-col cols="6" class="text-center">
                                            Fecha Inicio
                                            <h1 style="transform: scale(0.8);">{{cotizationLocal.CttDateInitial}}</h1>
                                        </v-col>
                                        <v-divider class="mt-3" vertical></v-divider>
                                        <v-col cols="6" class="text-center">
                                            Fecha Fin
                                            <h1 style="transform: scale(0.8);">{{cotizationLocal.CttDateEnd}}</h1>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mt-3"></v-divider>
                                    <v-row>
                                        <v-col cols="6" class="text-center">
                                            Tiempo Restante
                                            <h1 style="transform: scale(1.5);">{{cotizationLocal.CttTimeLeft}}</h1>
                                        </v-col>
                                        <v-divider class="mt-3" vertical></v-divider>
                                        <v-col cols="6" class="text-center">
                                            Tiempo Transcurrido
                                            <h1 style="transform: scale(1.5);">{{cotizationLocal.CttTimeElapsed}}</h1>
                                        </v-col>
                                    </v-row>
                                    <v-row style="margin:auto">
                                        <!-- <v-col cols="12">
                                            <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                                Fecha Inicio: {{cotizationLocal .CttDateInitial}}
                                                <br>Fecha Termino: {{cotizationLocal.CttDateEnd}}
                                                <br>Duración de Proceso: {{cotizationLocal.CttDayExpiration}} días
                                                <br>Nro. de Participantes: {{cotizationLocal.NroParticipants}}
                                                <br>
                                            </v-card-title>

                                        </v-col> -->

                                    </v-row>

                                </v-card>

                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-col cols="12" class="text-center">
                                        <h1 v-if="cotizationLocal.CttRequiredVisit  == 1" style="transform: scale(1.0);">Cotización requiere de vista a campo</h1>
                                        <h3 v-if="cotizationLocal.CttScheduledDate !== null">Fecha Programada: {{$moment(cotizationLocal.CttScheduledDate).format($const.FormatDateDB)}}</h3>
                                        <!-- <b style="color:red" >{{"Cotización requiere de vista a campo"}}</b> -->
                                    </v-col>

                                </v-card>
                            </v-row>
                        </v-col>

                    </v-col>

                    <v-divider vertical></v-divider>
                    <v-col lg="8" cols="12" class="" v-if="$fun.isAdmin()">
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined class="ml-3" width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        Participantes
                                    </v-card-title>
                                </v-card>
                            </v-row>
                        </v-col>
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card class="ml-3" outlined width="100%" style="margin-top:10px">
                                    <v-row style="margin:auto" v-if="isEditable">
                                        <v-col cols="6" lg="3" md="3">
                                            <s-text :outlined="false" label="Ruc Participante" number :autofocus="true" v-model="item.CtpRuc" ref="CtpRuc">
                                                <template v-slot:prepend>
                                                    <v-icon @click="searchSunarp($event)">mdi-magnify</v-icon>
                                                </template>
                                            </s-text>
                                        </v-col>
                                        <v-col cols="6" lg="4" md="4">
                                            <s-text :outlined="false" label="Nombre Participante" v-model="item.CtpName" ref="CtpName"></s-text>
                                        </v-col>
                                        <v-col cols="6" lg="3" md="3">
                                            <s-text :outlined="false" label="E-mail" v-model="item.CtpEmail" ref="CtpEmail"></s-text>
                                        </v-col>
                                        <v-col cols="12" lg="2" md="2" class="mt-3" v-if="isEditable && this.item.ProviderBloqueado == 0">

                                            <v-btn class="capitalize save" color="#2f5985" fab dark x-small elevation="0" @click="add()">
                                                <v-icon style="font-size:14px; border-radius: 10px" class="mr-0 ml-0 mb-0 mt-0">mdi-plus-circle-outline</v-icon>

                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                </v-card>
                            </v-row>
                        </v-col>
                        <v-row>
                            <v-col>
                                <v-data-table class="elevation-0 table table-hover table-condensed" style="font-family:" dense @click:row="rowClickParticipants" :headers="headersParticipants" :items-per-page="-1" v-model="selectedParticipants" :items="itemsParticipants" item-key="CtpID" :height="'auto'" hide-default-footer>
                                    <template v-slot:item.Aproved="{item}">
                                        <v-btn :disabled="item.CtpVisitAproved!==null || !isEditable" text small v-if="cotizationLocal.CttRequiredVisit  == 1" @click="aprovedVisit(item)">
                                            <v-icon style="font-size:16px;color:green"> far fa-thumbs-up</v-icon>
                                        </v-btn>
                                    </template>

                                    <template v-slot:item.Deleted="{item}">
                                        <v-btn :disabled="!isEditable" text small @click="deleteItem(item)">
                                            <v-icon style="font-size:16px; color:red"> mdi-minus-circle-outline</v-icon>
                                        </v-btn>
                                    </template>

                                    <!-- <template v-slot:item.MailOK="{ item }">
                                        <v-tooltip top="">
                                            <template v-slot:activator="{ on }" x-small>
                                                <v-btn v-on="on" icon small :color="item.MailOK == 1 ? 'success' : 'error'" @click="viewMail(item)" v-if="item.CttStatus == 1 || $fun.isAdmin()">
                                                    <i class="fas fa-envelope"></i>
                                                </v-btn>
                                            </template>
                                            <span>{{item.MailOK == 1 ? 'Email enviado' : 'Error al enviar Email'}}</span></v-tooltip>
                                    </template> -->

                                    <!-- <template v-slot:item.CtpStatus="{item}">

                                        <v-chip x-small :color="item.CtpStatus == 1 ? 'info' : item.CtpStatus == 2 ? 'success' : ''">{{item.CtpStatus == 1 ? 'No Cotizado' : item.CtpStatus == 2 ? 'Cotizado' : ''}}</v-chip>
                                    </template> -->
                                </v-data-table>
                            </v-col>
                        </v-row>

                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined class="ml-3" width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        Participantes - Cotizaciones recibidas
                                    </v-card-title>
                                </v-card>

                                <v-card v-if="cotizationLocal.CttReajustePrice==true" outlined class="ml-3" width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        <b style="color:red" class="mr-3">Importante ==></b> 
                                        <h1 style="transform: scale(0.7);">Solicitar a Participante reajuste en su Cotización.</h1>
                                        
                                    </v-card-title>
                                </v-card>

                                
                            </v-row>
                        </v-col>

                        <v-row>
                            <v-col>
                                <v-data-table class="elevation-0 table table-hover table-condensed" style="font-family:" dense :headers="headersReceived" :items-per-page="-1" :items="itemsReceived" item-key="CtpID" :height="'auto'" hide-default-footer>

                                    <template v-slot:item.Attach1="{item}">

                                        <div v-if="cotizationLocal.CttStatus == 1">
                                            <v-btn v-if="item.Attach1!==null && cotizationLocal.CttStatus == 1" color="success" class="mt-0" icon x-small @click="openFile({name: item.Attach1})">
                                                <v-icon>mdi-download</v-icon>
                                            </v-btn>
                                            {{item.Attach1}}
                                        </div>

                                    </template>

                                    <template v-slot:item.Approved="{item}">
                                        <v-btn icon text small @click="aprovedUpload(item, 1)" v-if="(item.ganador == 1 && item.IsActiveNewCot == null && cotizationLocal.CttReajustePrice == 1) && cotizationLocal.CttStatus == 1">
                                            <v-icon style="font-size:16px;color:green"> far fa-thumbs-up</v-icon>
                                        </v-btn>
                                    </template>

                                    <template v-slot:item.Dissaproved="{item}">
                                        <v-btn icon text small @click="aprovedUpload(item, null)" v-if="(item.ganador == 1 && item.IsActiveNewCot !== null && cotizationLocal.CttReajustePrice == 1) && cotizationLocal.CttStatus == 1">
                                            <v-icon style="font-size:16px; color:red"> far fa-thumbs-down</v-icon>
                                        </v-btn>
                                    </template>

                                    <template v-slot:item.ApprovedIsAdmin="{item}">
                                        <v-btn icon text small @click="aprovedUpload(item, 1)" v-if="item.IsActiveNewCot == null && $fun.isAdmin()">
                                            <v-icon style="font-size:16px;color:green"> far fa-thumbs-up</v-icon>
                                        </v-btn>
                                        <v-btn icon text small @click="aprovedUpload(item, null)" v-if="item.IsActiveNewCot !== null && $fun.isAdmin()">
                                            <v-icon style="font-size:16px; color:red"> far fa-thumbs-down</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>

                    </v-col>

                    <v-divider vertical></v-divider>
                    <v-col lg="12" cols="12">
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        Items Requeridos
                                    </v-card-title>
                                </v-card>
                            </v-row>
                        </v-col>
                        <v-row>
                            <v-col>
                                <v-data-table class="elevation-0 table table-hover table-condensed" style="font-family:" dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="CtdID" :height="'auto'" hide-default-footer>

                                </v-data-table>
                            </v-col>
                        </v-row>

                    </v-col>

                </v-row>
            </v-col>
        </v-card>
    </div>
</div>
</template>

<script>
//Services
import _sCotization from "@/services/Logistics/CotizationService";
import _sGeneral from "@/services/HelperService.js";
import _sSunarp from "@/services/General/GenSunarpService";
import _sSupplier from "@/services/Logistics/SupplierService";

export default {
    components: {},
    props: {
        value: {},
    },
    data() {
        return {
            processing: false,
            messageProcessing: "",
            dialogHtmlEmail: false,
            miHtmlEmail: null,

            selectedSAP: [],
            itemsSAP: [],
            headersSAP: [{
                    text: "Descripción",
                    value: "CtdDescription",
                    sortable: false,
                    width: 640,
                },
                {
                    text: "Cant",
                    value: "CtdQuantity",
                    sortable: false,
                },

                {
                    text: "Und",
                    value: "UnitMeasurement",
                    sortable: false,
                },
                {
                    text: "Comentario",
                    value: "CtdObservation",
                    sortable: false,
                },
            ],

            selectedParticipants: [],
            itemsParticipants: [],
            headersParticipants: [{
                    text: "Apro",
                    value: "Aproved",
                    sortable: false,
                    width: 10
                },
                {
                    text: "Removed",
                    value: "Deleted",
                    sortable: false,
                    width: 10
                },
                {
                    text: "Ruc Part.",
                    value: "CtpRuc",
                    sortable: false,
                },
                {
                    text: "Nombre Part.",
                    value: "CtpName",
                    sortable: false,
                },
                {
                    text: "Correo",
                    value: "CtpEmail",
                    sortable: false
                },
                /* {
                    text: "Ok",
                    value: "MailOK",
                    sortable: false
                }, */
                /* {
                    text: "Estado",
                    value: "CtpStatus",
                    sortable: false
                }, */
            ],

            headersReceived: [
                
                {
                    text: "A",
                    value: "ApprovedIsAdmin",
                    sortable: false,
                    width: 10
                },
                {
                    text: "Apro",
                    value: "Approved",
                    sortable: false,
                    width: 10
                },
                {
                    text: "Disapro",
                    value: "Dissaproved",
                    sortable: false,
                    width: 10
                },
                {
                    text: "Nombre Part.",
                    value: "CtpAlias",
                    sortable: false,
                },
                {
                    text: "Fec. Registro",
                    value: "CtpDateCotizacionReceived",
                    sortable: false
                },
                {
                    text: "Adjunto",
                    value: "Attach1",
                    sortable: false
                },
                {
                    text: "Total C.",
                    value: "CtpCountCotization",
                    sortable: false
                },

                

            ],

            itemsReceived: [],
            itemsRenegotiation: [],
            cotizationLocal: {},
            item: {},

        }
    },

    watch: {
        /* isEditable() {
            console.log(this.isEditable)
            this.processing = false;
        }, */

        'item.CtpRuc'() {
            this.processing = false
            this.messageProcessing = ""
        }
    },

    computed: {
        isEditable() {
            //return this.requirement.ReqStatus == 5 || this.requirement.ReqID == null || (this.requirement.ReqStatus == 3 && this.isUserCreated == true);
            return this.cotizationLocal.CttStatus == 4 && this.cotizationLocal.SecStatus == 3;

        },
    },

    created() {
        console.log(this.value)

        this.loadMain()

    },

    mounted() {
        if (this.value != null) {
            this.processing = true;
        }
    },

    methods: {

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 4).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        //***************************************** Item Detalle ******************************************************************** */
        //Seleccionar Fila
        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },

        rowClickParticipants: function (item, row) {
            this.selectedParticipants = [item];
        },

        loadMain() {
            if (this.value != null) {
                _sCotization
                    .byID({
                        CttID: this.value
                    }, this.$fun.getUserID())
                    .then((r) => {

                        console.log("data", r.data)
                        r.data.CttDate = this.$moment(r.data.CttDate).format(this.$const.FormatDateDB)
                        r.data.CttDateInitial = this.$moment(r.data.CttDateInitial).format(this.$const.FormatDateTimeDB)
                        r.data.CttDateEnd = this.$moment(r.data.CttDateEnd).format(this.$const.FormatDateTimeDB)

                        this.cotizationLocal = r.data
                        this.cotizationLocal.NroParticipants = r.data.itemsParticipants.length

                        r.data.itemsParticipants.forEach(element => {
                            element.CtpDateCotizacionReceived = this.$moment(element.CtpDateCotizacionReceived).format(this.$const.FormatDateTimeDB)
                        });

                        this.itemsParticipants = r.data.itemsParticipants
                        this.itemsSAP = r.data.items
                        this.itemsReceived = this.itemsParticipants.filter(x => x.CtpStatus == 2)
                        this.processing = false;

                        if (this.cotizationLocal.viewProvider == 1) {
                            this.headersReceived.splice(
                                3,
                                0, {
                                    text: "Ruc.",
                                    value: "CtpRuc",
                                    sortable: false
                                },
                            );
                        }

                    });
            }
        },

        searchSunarp() {

            if (this.item.CtpRuc == null || this.item.CtpRuc.length == 0) {
                return
            }

            if(this.item.CtpRuc.length < 11){
                alert("Nro. RUC inválido.")
                return
            }

            this.item.CtpName = null
            this.item.CtpEmail = null

            _sSupplier.byRuc(this.item.CtpRuc, this.$fun.getUserID()).then(r => {
                if (r.status == 200) {

                    var objProvider = r.data;
                    console.log("dataaaaaaaaaaa", objProvider)

                    if (objProvider == null) {
                        this.messageProcessing = ""
                        this.processing = true
                        _sSunarp.searchsunarp(this.item.CtpRuc, this.$fun.getUserID()).then(resp => {
                                console.log(resp.data)
                                if (resp.status == 200) {
                                    this.item.ProviderBloqueado = 0
                                    this.item.CtpName = resp.data.SunName
                                    this.processing = false
                                }
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = true;
                                this.item.CtpName = ""
                            });
                    } else {
                        this.item.ProviderBloqueado = objProvider.ProviderBloqueado

                        if (objProvider.ProviderBloqueado > 0) {
                            this.$fun.alert("Proveedor [" + objProvider.SupName + "] en Lista Negra", "warning")
                            return
                        } else {
                            this.item = {
                                CtpRuc: objProvider.SupID,
                                CtpName: objProvider.SupName,
                                CtpEmail: objProvider.ConEmail,
                                ProviderBloqueado: objProvider.ProviderBloqueado
                            }

                        }
                    }

                }
            });

        },

        add() {
            console.log(this.item)

            if (this.item.CtpRuc == null || this.item.CtpRuc == "" || this.item.CtpRuc.length == 0) {
                this.$refs.CtpRuc.error("Ingrese Ruc")
                return
            }

            if (this.item.CtpRuc.length < 8 || this.item.CtpRuc.length > 11) {
                this.$refs.CtpRuc.error("Documento ingresado no es correcto")
                return
            }

            if (this.item.ProviderBloqueado > 0) {
                this.$fun.alert("[IMP]. Proveedor en Lista Negra", "warning")
                return
            }

            if (this.item.CtpName == null || this.item.CtpName == "" || this.item.CtpName.length == 0) {
                this.$refs.CtpName.error("Ingrese Nombre de Participante")
                return
            }

            if (this.item.CtpName.length < 3) {
                this.$refs.CtpName.error("Nombre ingresado incorrecto")
                return
            }

            if (this.item.CtpEmail == null || this.item.CtpEmail == "" || this.item.CtpEmail.length == 0) {
                this.$refs.CtpEmail.error("Ingrese Email")
                return
            }

            if (!this.$fun.isValidEmail(this.item.CtpEmail)) {
                this.$refs.CtpEmail.error("Email invalido")
                return
            }

            if (
                this.itemsParticipants.find(
                    (x) =>
                    x.CtpRuc == this.item.CtpRuc
                )
            ) {
                this.$fun.alert("Ruc de Participante ya fue agregada", "warning");
                return;
            }

            this.item.CttID = this.cotizationLocal.CttID
            this.cotizationLocal.CttDayExpiration = this.cotizationLocal.DiferrenceHour
            this.cotizationLocal.UmDuracion = "horas"
            this.cotizationLocal.itemsParticipants = [this.item]
            this.cotizationLocal.UsrCreateID = this.$fun.getUserID()

            console.log("save", this.cotizationLocal)

            this.$fun.alert("Seguro de añadir nuevo Participante a la Cotización ?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true
                    _sCotization.saveParticipants(this.cotizationLocal, this.$fun.getUserID()).then(resp => {
                            if (resp.status == 200) {
                                this.processing = false
                                this.cotizationLocal.itemsParticipants = []
                                this.loadMain()
                                this.$fun.alert("Añadido correctamente", "success")
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            })
        },

        deleteItem(item) {

            if (this.itemsParticipants.length == 1) {
                this.$fun.alert("No puede eliminar el Unico registro", "error")
                return
            }
            this.$fun.alert("Seguro de eliminar Participante de la Cotización ?", "question").then(val => {
                if (val.value) {
                    item.UsrUpdateID = this.$fun.getUserID()
                    item.SecStatus = 0
                    this.messageProcessing = ""
                    this.processing = true
                    _sCotization.updateParticipants(item, this.$fun.getUserID()).then(resp => {
                            if (resp.status == 200) {
                                this.processing = false
                                this.loadMain()
                                this.$fun.alert("Actualizado correctamente", "success")
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            })
        },

        aprovedVisit(item) {

            console.log(item)

            if (item.CtpID <= 0) {
                return
            }

            this.$fun.alert("Aprobar visitar a campo ?", "question").then(val => {
                if (val.value) {
                    item.CtpVisitAproved = 1
                    item.UsrUpdateID = this.$fun.getUserID()
                    item.SecStatus = 1

                    this.messageProcessing = ""
                    this.processing = true
                    _sCotization.updateParticipants(item, this.$fun.getUserID()).then(resp => {
                            if (resp.status == 200) {
                                this.processing = false
                                this.loadMain()
                                this.$fun.alert("Visita aprobada correctamente", "success")
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            })
        },

        aprovedUpload(item, status) {
            item.IsActiveNewCot = status
            item.SecStatus = 11
            item.UsrUpdateID = this.$fun.getUserID()
            _sCotization.save(item, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {}
            });
        },

        viewMail(item) {
            if (item.MailOK == 1) {
                this.miHtmlEmail = item.MailBody
                this.dialogHtmlEmail = true
            }

        }

    }
};
</script>

<template>
<div>
    <lgs-requirement label="Requerimientos"></lgs-requirement>
</div>
</template>

<script>
import LgsRequirement from './LgsRequirement.vue';
export default {
    name: "LgsRequirementPurchase",
    components: {
        LgsRequirement
    },
    props: {

    },
    data: () => ({

    }),

};
</script>

import Service from "../Service";

const resource = "requirement/";

export default {
    detail(req, requestID) {
        return Service.post(resource + "detailByRequirement", req, {
            params: { requestID: requestID },
        });
    },

    history(parameters, requestID) {
        return Service.post(resource + "historyByRequirement", parameters, {
            params: { requestID: requestID },
        });
    },

    save(cmp, requestID) {
        return Service.post(resource + "saveDetail", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "paginationDetail", parameters, {
            params: { requestID: requestID },
        });
    },


};